import { createTheme } from '@mui/material/styles';

// צבעים חדשים מודרניים
const primaryBlue = '#2196f3'; // כחול מודרני
const secondaryBlue = '#1976d2'; // כחול כהה יותר
const darkGray = '#333333'; // אפור כהה/שחור
const lightGray = '#f5f5f5'; // אפור בהיר לרקעים

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  direction: 'rtl',
  shape: {
    borderRadius: 0, // עיצוב יותר חד ומודרני
  },
  typography: {
    fontFamily: "'Heebo', sans-serif",
    h1: {
      fontWeight: 900, // יותר עבה
      letterSpacing: '-0.02em',
      textTransform: 'uppercase',
    },
    h2: {
      fontWeight: 700,
      letterSpacing: '-0.01em',
    },
    h3: {
      fontWeight: 600,
      letterSpacing: '-0.01em',
    },
    h4: {
      fontWeight: 600,
    },
    button: {
      fontWeight: 600,
    },
  },
  palette: {
    primary: {
      main: primaryBlue,
      light: '#64b5f6',
      dark: secondaryBlue,
      contrastText: '#ffffff',
    },
    secondary: {
      main: darkGray,
      light: '#757575',
      dark: '#212121',
      contrastText: '#ffffff',
    },
    text: {
      primary: darkGray,
      secondary: '#757575',
    },
    background: {
      default: '#ffffff',
      paper: '#ffffff',
      dark: darkGray,
      light: lightGray,
    },
    divider: 'rgba(0, 0, 0, 0.08)',
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          borderRadius: '30px', // כפתורים מעוגלים יותר
          padding: '10px 24px',
          fontWeight: 600,
          position: 'relative',
          overflow: 'hidden',
          transition: 'all 0.3s cubic-bezier(0.2, 0.8, 0.2, 1)',
          '&:hover': {
            transform: 'translateY(-3px)',
            boxShadow: '0 7px 14px rgba(0, 0, 0, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08)',
          },
        },
        contained: {
          boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
          '&:hover': {
            boxShadow: '0 10px 15px rgba(0, 0, 0, 0.15)',
          },
        },
        containedPrimary: {
          '&:hover': {
            backgroundColor: '#c41c21', // כהה יותר בהובר
          },
        },
        outlined: {
          borderWidth: '2px',
          '&:hover': {
            borderWidth: '2px',
          },
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: 0,
          boxShadow: 'none',
          border: `1px solid rgba(0, 0, 0, 0.08)`,
          transition: 'transform 0.4s ease-out, box-shadow 0.4s ease-out',
          overflow: 'hidden',
          '&:hover': {
            transform: 'translateY(-8px)',
            boxShadow: '0px 8px 24px rgba(0, 0, 0, 0.12)',
          },
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          borderBottom: `1px solid rgba(0, 0, 0, 0.08)`,
        },
      },
    },
    MuiContainer: {
      styleOverrides: {
        root: {
          paddingLeft: 24,
          paddingRight: 24,
          '@media (min-width: 600px)': {
            paddingLeft: 32,
            paddingRight: 32,
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root': {
            borderRadius: 0,
          },
        },
      },
    },
  },
});

export default theme;
