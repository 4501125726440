import React from 'react';
import { 
  Box, 
  Container, 
  Typography, 
  Grid, 
  Card, 
  CardContent,
  Avatar,
  Divider,
  Paper
} from '@mui/material';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import EngineeringIcon from '@mui/icons-material/Engineering';
import HandshakeIcon from '@mui/icons-material/Handshake';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import VerifiedIcon from '@mui/icons-material/Verified';

function About() {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.3
      }
    }
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: { duration: 0.5 }
    }
  };

  const values = [
    {
      title: 'מקצועיות',
      icon: EngineeringIcon,
      description: 'צוות מומחים מוסמך עם ניסיון רב בתכנון והתקנת מערכות אבטחה ומתח נמוך.',
    },
    {
      title: 'אמינות',
      icon: HandshakeIcon,
      description: 'עמידה בלוחות זמנים, שקיפות מלאה ומחויבות לשביעות רצון הלקוח.',
    },
    {
      title: 'שירות',
      icon: SupportAgentIcon,
      description: 'זמינות גבוהה, מענה מהיר ותמיכה טכנית 24/7 לכל בעיה או שאלה.',
    },
    {
      title: 'איכות',
      icon: VerifiedIcon,
      description: 'שימוש בציוד מהמותגים המובילים בשוק עם אחריות מלאה על כל התקנה.',
    },
  ];

  return (
    <Box>
      {/* Hero Section */}
      <Box 
        sx={{ 
          py: 12, 
          background: 'linear-gradient(135deg, #2196f3 0%, #1976d2 100%)',
          color: 'white',
          position: 'relative',
          overflow: 'hidden',
          boxShadow: '0 4px 20px rgba(0,0,0,0.1)',
        }}
      >
        {/* Decorative elements */}
        <Box 
          sx={{
            position: 'absolute',
            top: -100,
            right: -100,
            width: 300,
            height: 300,
            borderRadius: '50%',
            background: 'rgba(255,255,255,0.1)',
            zIndex: 1,
          }}
        />
        <Box 
          sx={{
            position: 'absolute',
            bottom: -50,
            left: -50,
            width: 200,
            height: 200,
            borderRadius: '50%',
            background: 'rgba(255,255,255,0.1)',
            zIndex: 1,
          }}
        />
        <Container maxWidth="lg" sx={{ position: 'relative', zIndex: 2 }}>
          <motion.div
            initial={{ opacity: 0, y: 30 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
          >
            <Typography 
              variant="h2" 
              component="h1" 
              gutterBottom
              sx={{ 
                fontWeight: 900,
                fontSize: { xs: '2.5rem', md: '4rem' },
                textShadow: '0 2px 10px rgba(0,0,0,0.2)',
              }}
            >
              אודות
            </Typography>
            <Typography 
              variant="h5"
              sx={{ 
                maxWidth: '800px',
                lineHeight: 1.6,
                textShadow: '0 1px 5px rgba(0,0,0,0.1)',
              }}
            >
              חברת שיא מיגון - פתרונות אבטחה ומתח נמוך מתקדמים
            </Typography>
          </motion.div>
        </Container>
      </Box>

      {/* About Content */}
      <Container maxWidth="lg" sx={{ py: 12 }}>
        <Grid container spacing={8}>
          <Grid item xs={12} md={6}>
            <motion.div
              initial={{ opacity: 0, x: -30 }}
              whileInView={{ opacity: 1, x: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.8 }}
            >
              <Typography 
                variant="h4" 
                component="h2" 
                gutterBottom
                sx={{ 
                  fontWeight: 700,
                  position: 'relative',
                  '&:after': {
                    content: '""',
                    position: 'absolute',
                    bottom: -10,
                    left: 0,
                    width: 80,
                    height: 4,
                    background: 'linear-gradient(90deg, #2196f3, #64b5f6)',
                    borderRadius: 2,
                  }
                }}
              >
                מי אנחנו
              </Typography>
              <Typography 
                variant="body1" 
                paragraph
                sx={{ 
                  fontSize: '1.1rem', 
                  lineHeight: 1.8,
                  color: 'text.secondary',
                }}
              >
                חברת שיא מיגון הוקמה בשנת 2010 במטרה לספק פתרונות אבטחה ומתח נמוך מתקדמים לבתים פרטיים, עסקים ומוסדות.
              </Typography>
            </motion.div>
            <motion.div
              initial={{ opacity: 0, x: -30 }}
              whileInView={{ opacity: 1, x: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.8, delay: 0.2 }}
            >
              <Typography 
                variant="body1" 
                paragraph
                sx={{ 
                  fontSize: '1.1rem', 
                  lineHeight: 1.8,
                  color: 'text.secondary',
                  mt: 2
                }}
              >
                אנו מתמחים בתכנון, התקנה ותחזוקה של מערכות מצלמות אבטחה, מערכות אזעקה, בקרת כניסה, אינטרקום ומגוון פתרונות מתח נמוך.
              </Typography>
            </motion.div>
            <motion.div
              initial={{ opacity: 0, x: -30 }}
              whileInView={{ opacity: 1, x: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.8, delay: 0.4 }}
            >
              <Typography 
                variant="body1" 
                paragraph
                sx={{ 
                  fontSize: '1.1rem', 
                  lineHeight: 1.8,
                  color: 'text.secondary',
                  mt: 2
                }}
              >
                הצוות שלנו מורכב ממומחים מוסמכים עם ניסיון רב בתחום, המחויבים לספק את השירות הטוב ביותר ללקוחותינו.
              </Typography>
            </motion.div>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box
              component="img"
              src="https://source.unsplash.com/random/600x400/?security,team"
              alt="צוות שיא מיגון"
              sx={{
                width: '100%',
                height: 'auto',
                borderRadius: 2,
                boxShadow: 3,
              }}
            />
          </Grid>
        </Grid>

        <Divider sx={{ my: 8 }} />

        {/* Our Values */}
        <Box 
          ref={ref}
          component={motion.div}
          variants={containerVariants}
          initial="hidden"
          animate={inView ? "visible" : "hidden"}
          sx={{ mt: 6 }}
        >
          <Typography variant="h4" component="h2" gutterBottom align="center" sx={{ mb: 6 }}>
            הערכים שלנו
          </Typography>
          
          <Grid container spacing={4}>
            {values.map((value, index) => (
              <Grid item xs={12} sm={6} md={3} key={index}>
                <Card 
                  component={motion.div}
                  variants={itemVariants}
                  sx={{ height: '100%' }}
                >
                  <CardContent sx={{ textAlign: 'center' }}>
                    <Avatar
                      sx={{
                        bgcolor: 'primary.main',
                        width: 70,
                        height: 70,
                        mx: 'auto',
                        mb: 2,
                      }}
                    >
                      <value.icon fontSize="large" />
                    </Avatar>
                    <Typography variant="h5" component="h3" gutterBottom>
                      {value.title}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {value.description}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Box>

        <Divider sx={{ my: 8 }} />

        {/* Our Approach */}
        <Box component={motion.div}
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          viewport={{ once: true }}
          transition={{ duration: 0.8 }}
        >
          <Typography variant="h4" component="h2" gutterBottom align="center" sx={{ mb: 6 }}>
            הגישה שלנו
          </Typography>
          
          <Paper 
            elevation={3} 
            sx={{ 
              p: 4, 
              borderRadius: 2,
              background: 'linear-gradient(to bottom right, #ffffff, #f5f5f5)',
              boxShadow: '0 10px 30px rgba(0,0,0,0.08)',
              overflow: 'hidden',
              position: 'relative',
              '&::before': {
                content: '""',
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '5px',
                background: 'linear-gradient(to right, #2196f3, #64b5f6)',
              }
            }}
          >
            <Grid container spacing={4}>
              <Grid item xs={12} md={6}>
                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  viewport={{ once: true }}
                  transition={{ duration: 0.6 }}
                >
                  <Typography 
                    variant="h5" 
                    component="h3" 
                    gutterBottom
                    sx={{ 
                      fontWeight: 700,
                      color: 'primary.main',
                      display: 'flex',
                      alignItems: 'center',
                      '&::before': {
                        content: '""',
                        display: 'inline-block',
                        width: '12px',
                        height: '12px',
                        borderRadius: '50%',
                        backgroundColor: 'primary.main',
                        marginLeft: '8px',
                      }
                    }}
                  >
                    פתרון מותאם אישית
                  </Typography>
                  <Typography 
                    variant="body1" 
                    paragraph
                    sx={{ 
                      fontSize: '1.05rem',
                      lineHeight: 1.8,
                    }}
                  >
                    אנו מאמינים שכל בית ועסק זקוק לפתרון אבטחה המותאם לצרכים הספציפיים שלו. לכן, אנו מתחילים כל פרויקט בפגישת ייעוץ מקיפה בה אנו לומדים את הצרכים והדרישות של הלקוח.
                  </Typography>
                  <Typography 
                    variant="body1"
                    sx={{ 
                      fontSize: '1.05rem',
                      lineHeight: 1.8,
                    }}
                  >
                    לאחר הפגישה, אנו מתכננים מערכת המותאמת במיוחד עבורכם, תוך שימוש בטכנולוגיות המתקדמות ביותר בשוק ובהתאם לתקציב שהוגדר.
                  </Typography>
                </motion.div>
              </Grid>
              <Grid item xs={12} md={6}>
                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  viewport={{ once: true }}
                  transition={{ duration: 0.6, delay: 0.2 }}
                >
                  <Typography 
                    variant="h5" 
                    component="h3" 
                    gutterBottom
                    sx={{ 
                      fontWeight: 700,
                      color: 'primary.main',
                      display: 'flex',
                      alignItems: 'center',
                      '&::before': {
                        content: '""',
                        display: 'inline-block',
                        width: '12px',
                        height: '12px',
                        borderRadius: '50%',
                        backgroundColor: 'primary.main',
                        marginLeft: '8px',
                      }
                    }}
                  >
                    שירות מקצה לקצה
                  </Typography>
                  <Typography 
                    variant="body1" 
                    paragraph
                    sx={{ 
                      fontSize: '1.05rem',
                      lineHeight: 1.8,
                    }}
                  >
                    אנו מספקים שירות מלא מהייעוץ הראשוני ועד לתחזוקה השוטפת של המערכת לאורך זמן.
                  </Typography>
                  <Typography 
                    variant="body1" 
                    paragraph
                    sx={{ 
                      fontSize: '1.05rem',
                      fontWeight: 500,
                      mt: 2,
                    }}
                  >
                    השירות שלנו כולל:
                  </Typography>
                  <Box sx={{ pl: 2 }}>
                    <ul style={{ listStyleType: 'none', padding: 0 }}>
                      {[
                        'ייעוץ ותכנון מערכת',
                        'אספקת ציוד איכותי מהמותגים המובילים',
                        'התקנה מקצועית על ידי טכנאים מוסמכים',
                        'הדרכה מקיפה על השימוש במערכת',
                        'שירות ותמיכה טכנית 24/7',
                        'אחריות מלאה על כל התקנה'
                      ].map((item, index) => (
                        <motion.li 
                          key={index}
                          initial={{ opacity: 0, x: -10 }}
                          whileInView={{ opacity: 1, x: 0 }}
                          viewport={{ once: true }}
                          transition={{ duration: 0.3, delay: 0.3 + (index * 0.1) }}
                          style={{ 
                            marginBottom: '8px',
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          <Box 
                            component="span"
                            sx={{ 
                              display: 'inline-block',
                              width: '6px',
                              height: '6px',
                              borderRadius: '50%',
                              backgroundColor: 'primary.main',
                              marginLeft: '8px',
                            }}
                          />
                          <Typography 
                            variant="body1"
                            sx={{ 
                              fontSize: '1.05rem',
                            }}
                          >
                            {item}
                          </Typography>
                        </motion.li>
                      ))}
                    </ul>
                  </Box>
                </motion.div>
              </Grid>
            </Grid>
          </Paper>
        </Box>
      </Container>
    </Box>
  );
}

export default About;
