import React, { useState, useEffect } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import {
  AppBar,
  Box,
  Toolbar,
  IconButton,
  Typography,
  Menu,
  Container,
  Button,
  MenuItem,
  useScrollTrigger,
  Slide,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';

const pages = [
  { title: 'דף הבית', path: '/' },
  { title: 'אודות', path: '/about' },
  { title: 'השירותים שלנו', path: '/services' },
  { title: 'צור קשר', path: '/contact' },
];

// רכיב להסתרת הנאבבר בגלילה למטה
function HideOnScroll(props) {
  const { children } = props;
  const trigger = useScrollTrigger();

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

function Navbar() {
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [scrolled, setScrolled] = useState(false);
  const location = useLocation();

  // מעקב אחר גלילה לשינוי עיצוב הנאבבר
  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;
      if (offset > 100) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (
    <HideOnScroll>
      <AppBar 
        position="fixed" 
        sx={{
          backgroundColor: scrolled ? 'white' : 'transparent',
          boxShadow: scrolled ? 1 : 'none',
          transition: 'all 0.3s ease',
          py: scrolled ? 0 : 1,
        }}
      >
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            {/* Logo for desktop */}
            <Box 
              sx={{
                display: { xs: 'none', md: 'flex' },
                alignItems: 'center',
                mr: 4,
              }}
            >
              <Box 
                component="img"
                src="https://i.ibb.co/JdM4FXd/sia-migun-logo.png" 
                alt="שיא מיגון"
                sx={{
                  height: 60,
                  transition: 'transform 0.3s ease',
                  '&:hover': {
                    transform: 'scale(1.05)',
                  },
                }}
              />
            </Box>

            {/* Mobile menu */}
            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
              <IconButton
                size="large"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                sx={{
                  color: scrolled ? 'primary.main' : 'white',
                }}
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: 'block', md: 'none' },
                  '& .MuiPaper-root': {
                    backgroundColor: 'background.paper',
                    borderTop: '3px solid',
                    borderColor: 'primary.main',
                    mt: 1,
                  },
                }}
              >
                {pages.map((page) => (
                  <MenuItem 
                    key={page.path} 
                    onClick={handleCloseNavMenu}
                    component={RouterLink}
                    to={page.path}
                    selected={location.pathname === page.path}
                    sx={{
                      borderRight: location.pathname === page.path ? '3px solid' : 'none',
                      borderColor: 'primary.main',
                      backgroundColor: location.pathname === page.path ? 'rgba(227, 30, 36, 0.08)' : 'transparent',
                      '&:hover': {
                        backgroundColor: 'rgba(227, 30, 36, 0.08)',
                      },
                    }}
                  >
                    <Typography textAlign="center">{page.title}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>

            {/* Logo for mobile */}
            <Box 
              sx={{
                flexGrow: 1,
                display: { xs: 'flex', md: 'none' },
                justifyContent: 'center',
              }}
            >
              <Box 
                component="img"
                src="https://i.ibb.co/JdM4FXd/sia-migun-logo.png" 
                alt="שיא מיגון"
                sx={{
                  height: 50,
                }}
              />
            </Box>

            {/* Desktop menu */}
            <Box 
              sx={{ 
                flexGrow: 1, 
                display: { xs: 'none', md: 'flex' }, 
                justifyContent: 'flex-end',
              }}
            >
              {pages.map((page) => {
                const isActive = location.pathname === page.path;
                return (
                  <Button
                    key={page.path}
                    component={RouterLink}
                    to={page.path}
                    onClick={handleCloseNavMenu}
                    sx={{
                      mx: 1,
                      color: scrolled ? (isActive ? 'primary.main' : 'text.primary') : 'white',
                      display: 'block',
                      position: 'relative',
                      fontWeight: isActive ? 700 : 500,
                      '&::after': {
                        content: '""',
                        position: 'absolute',
                        bottom: 0,
                        left: 0,
                        width: '100%',
                        height: '2px',
                        backgroundColor: 'primary.main',
                        transform: isActive ? 'scaleX(1)' : 'scaleX(0)',
                        transformOrigin: 'center',
                        transition: 'transform 0.3s ease',
                      },
                      '&:hover': {
                        backgroundColor: 'transparent',
                      },
                      '&:hover::after': {
                        transform: 'scaleX(1)',
                      },
                    }}
                  >
                    {page.title}
                  </Button>
                );
              })}
              <Button
                variant="contained"
                component={RouterLink}
                to="/contact"
                sx={{
                  ml: 3,
                  px: 3,
                  backgroundColor: scrolled ? 'primary.main' : 'white',
                  color: scrolled ? 'white' : 'primary.main',
                  '&:hover': {
                    backgroundColor: scrolled ? 'primary.dark' : 'rgba(255, 255, 255, 0.9)',
                  },
                }}
              >
                צור קשר
              </Button>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </HideOnScroll>
  );
}

export default Navbar;
