import React, { useEffect, useRef } from 'react';
import { Button } from '@mui/material';
import gsap from 'gsap';

// קומפוננטה לכפתור מגנטי עם אפקטים מתקדמים
const MagneticButton = ({ children, strength = 50, radius = 150, ...props }) => {
  const buttonRef = useRef(null);
  const boundingRef = useRef(null);
  
  useEffect(() => {
    if (!buttonRef.current) return;
    
    const button = buttonRef.current;
    
    // אחסון מידות הכפתור
    const updateBounding = () => {
      if (!button) return;
      boundingRef.current = button.getBoundingClientRect();
    };
    
    // עדכון מידות בעת שינוי גודל החלון
    window.addEventListener('resize', updateBounding);
    updateBounding();
    
    // טיפול בתנועת העכבר - רק כאשר העכבר מעל הכפתור
    const handleMouseMove = (e) => {
      if (!boundingRef.current) return;
      
      const { clientX, clientY } = e;
      const { left, top, width, height } = boundingRef.current;
      
      // חישוב מרכז הכפתור
      const centerX = left + width / 2;
      const centerY = top + height / 2;
      
      // חישוב כמה להזיז את הכפתור
      const x = ((clientX - centerX) / radius) * strength;
      const y = ((clientY - centerY) / radius) * strength;
      
      // אנימציה חלקה לכפתור
      gsap.to(button, {
        x: x,
        y: y,
        scale: 1.1,
        duration: 0.3,
        ease: 'power2.out'
      });
    };
    
    // טיפול ביציאת העכבר
    const handleMouseLeave = () => {
      gsap.to(button, {
        x: 0,
        y: 0,
        scale: 1,
        duration: 0.5,
        ease: 'elastic.out(1, 0.3)'
      });
    };
    
    // טיפול בכניסת העכבר
    const handleMouseEnter = () => {
      // עדכון מידות הכפתור בכניסה
      updateBounding();
    };
    
    // הוספת מאזינים לאירועים - רק על הכפתור עצמו
    button.addEventListener('mousemove', handleMouseMove);
    button.addEventListener('mouseleave', handleMouseLeave);
    button.addEventListener('mouseenter', handleMouseEnter);
    
    // שמירת הכפתור במשתנה לפונקציית הניקוי
    const buttonElement = button;
    
    return () => {
      // הסרת מאזינים רק מהכפתור
      if (buttonElement) {
        buttonElement.removeEventListener('mousemove', handleMouseMove);
        buttonElement.removeEventListener('mouseleave', handleMouseLeave);
        buttonElement.removeEventListener('mouseenter', handleMouseEnter);
      }
      window.removeEventListener('resize', updateBounding);
    };
  }, [radius, strength]);
  
  return (
    <Button
      ref={buttonRef}
      {...props}
      sx={{
        position: 'relative',
        transition: 'transform 0.01s',
        transformStyle: 'preserve-3d',
        willChange: 'transform',
        ...props.sx
      }}
    >
      {children}
    </Button>
  );
};

export default MagneticButton;
