import React, { useEffect, useRef, useState } from 'react';
import { 
  Box, 
  Container, 
  Typography, 
  Grid, 
  Card, 
  CardContent, 
  List, 
  ListItem, 
  ListItemIcon, 
  ListItemText,
  Button,
  IconButton,
  useTheme,
  useMediaQuery
} from '@mui/material';
import { Link } from 'react-router-dom';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import SecurityIcon from '@mui/icons-material/Security';
import ElectricalServicesIcon from '@mui/icons-material/ElectricalServices';
import WifiIcon from '@mui/icons-material/Wifi';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PhoneIcon from '@mui/icons-material/Phone';
import { initializeAnimations } from '../utils/scrollAnimation';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

// ייבוא הקומפוננטות החדשות
import TextAnimation from '../components/TextAnimation';
import ParallaxLayer from '../components/ParallaxLayer';
import FloatingElements from '../components/FloatingElements';
import ScrollProgress from '../components/ScrollProgress';
import MagneticButton from '../components/MagneticButton';

// רישום פלאגין ScrollTrigger של GSAP
gsap.registerPlugin(ScrollTrigger);

function Services() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [scrollY, setScrollY] = useState(0);
  const [activeSection, setActiveSection] = useState('hero');
  const [isLoaded, setIsLoaded] = useState(false);
  
  // הגדרת רפרנסים לסקשנים
  const heroRef = useRef(null);
  const servicesRef = useRef(null);
  const contactRef = useRef(null);
  const serviceItemsRef = useRef([]);
  
  // הגדרת refs לאנימציות GSAP
  const titleRef = useRef(null);
  const subtitleRef = useRef(null);
  const ctaRef = useRef(null);
  
  // אפקט הטעינה הראשוני
  useEffect(() => {
    // הגדרת טיימר לטעינה
    const timer = setTimeout(() => {
      setIsLoaded(true);
    }, 500);
    
    return () => clearTimeout(timer);
  }, []);

  // אתחול אנימציות גלילה
  useEffect(() => {
    initializeAnimations();
    
    // הוספת אירוע גלילה
    const handleScroll = () => {
      setScrollY(window.scrollY);
      
      // זיהוי הסקשן הפעיל
      const heroRect = heroRef.current?.getBoundingClientRect();
      const servicesRect = servicesRef.current?.getBoundingClientRect();
      const contactRect = contactRef.current?.getBoundingClientRect();
      
      if (heroRect && heroRect.bottom > 0) {
        setActiveSection('hero');
      } else if (servicesRect && servicesRect.top < window.innerHeight / 2 && servicesRect.bottom > 0) {
        setActiveSection('services');
      } else if (contactRect && contactRect.top < window.innerHeight) {
        setActiveSection('contact');
      }
    };
    
    window.addEventListener('scroll', handleScroll);
    handleScroll(); // הפעלה ראשונית
    
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  
  // אתחול אנימציות GSAP
  useEffect(() => {
    // וידוא שכל ה-refs קיימים לפני הפעלת האנימציות
    if (!isLoaded) return;
    if (!titleRef.current || !subtitleRef.current || !ctaRef.current) {
      console.warn('Some refs are not available yet');
      return;
    }

    try {
      // אנימציית הכותרת והטקסט
      const tl = gsap.timeline({ defaults: { ease: "power3.out" } });
      
      // אנימציית כותרת
      tl.from(titleRef.current, { 
        y: 50, 
        opacity: 0, 
        duration: 1,
        delay: 0.2
      });
      
      // אנימציית תת-כותרת
      if (subtitleRef.current) {
        tl.from(subtitleRef.current, { 
          y: 30, 
          opacity: 0, 
          duration: 0.8 
        }, "-=0.6");
      }
      
      // אנימציית כפתורים
      if (ctaRef.current) {
        tl.from(ctaRef.current, { 
          y: 20, 
          opacity: 0, 
          duration: 0.6 
        }, "-=0.4");
      }
      
      // אנימציות לכרטיסי השירותים
      if (serviceItemsRef.current && serviceItemsRef.current.length > 0) {
        gsap.from(serviceItemsRef.current, {
          y: 50,
          opacity: 0,
          duration: 0.8,
          stagger: 0.2,
          scrollTrigger: {
            trigger: servicesRef.current,
            start: "top 70%",
            toggleActions: "play none none none"
          }
        });
      }
      
      // ניקוי בעת unmount
      return () => {
        if (tl) tl.kill();
        ScrollTrigger.getAll().forEach(trigger => trigger.kill());
      };
    } catch (error) {
      console.error('Error initializing GSAP animations:', error);
    }
  }, [isLoaded]);

  const services = [
    {
      title: 'מצלמות אבטחה',
      icon: CameraAltIcon,
      description: 'מערכות מצלמות אבטחה מתקדמות המותאמות לצרכים הספציפיים שלכם, עם אפשרות לצפייה מרחוק ויכולות מתקדמות.',
      features: ['צפייה מרחוק 24/7', 'הקלטה באיכות HD', 'זיהוי תנועה חכם', 'אחסון בענן', 'התראות בזמן אמת'],
    },
    {
      title: 'מערכות אזעקה',
      icon: SecurityIcon,
      description: 'מערכות אזעקה מתקדמות המספקות הגנה מקיפה לבית או לעסק שלכם, עם אפשרות לשליטה מרחוק וחיבור למוקד.',
      features: ['חיישני תנועה מתקדמים', 'חיישני דלתות וחלונות', 'שליטה מהטלפון הנייד', 'חיבור למוקד אבטחה', 'גיבוי סוללה'],
    },
    {
      title: 'מערכות מתח נמוך',
      icon: ElectricalServicesIcon,
      description: 'התקנת מערכות מתח נמוך מגוונות כולל אינטרקום, בקרת כניסה, מערכות אודיו-וידאו ועוד.',
      features: ['אינטרקום וידאו', 'בקרת כניסה', 'מערכות אודיו-וידאו', 'רשתות תקשורת', 'בית חכם'],
    },
    {
      title: 'רשתות ותקשורת',
      icon: WifiIcon,
      description: 'פריסת רשתות תקשורת אלחוטיות וקוויות, התקנת נקודות גישה, ראוטרים ומתגים לביצועים מיטביים.',
      features: ['פריסת רשת אלחוטית', 'התקנת נקודות גישה', 'רשתות קוויות', 'אבטחת רשת', 'גיבוי אינטרנט'],
    },
  ];

  return (
    <Box sx={{ overflow: 'hidden' }}>
      {/* Hero Section */}
      <Box 
        ref={heroRef}
        className={`hero-section ${activeSection === 'hero' ? 'active-section' : ''}`}
        sx={{ 
          position: 'relative', 
          overflow: 'hidden',
          backgroundImage: 'linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.7)), url(https://source.unsplash.com/random/1600x900/?security,technology)',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundAttachment: 'fixed',
          height: '100vh',
          display: 'flex',
          alignItems: 'center',
          color: 'white',
          transition: 'all 0.8s cubic-bezier(0.2, 0.8, 0.2, 1)',
        }}
      >
        <Container maxWidth="lg">
          <Box 
            sx={{ 
              transform: `translateY(${scrollY * 0.3}px)`,
              opacity: 1 - scrollY / 700,
              transition: 'transform 0.2s ease-out',
            }}
          >
            <Box ref={titleRef}>
              <TextAnimation 
                text="השירותים שלנו" 
                variant="h1" 
                component="h1" 
                delay={0.3}
                duration={0.8}
                sx={{ 
                  fontWeight: 900, 
                  mb: 3, 
                  fontSize: { xs: '2.5rem', md: '4.5rem' },
                  textShadow: '0 2px 10px rgba(0,0,0,0.3)',
                  position: 'relative',
                  '&:after': {
                    content: '""',
                    position: 'absolute',
                    bottom: '-15px',
                    left: '0',
                    width: '100px',
                    height: '5px',
                    backgroundColor: '#2196f3',
                    borderRadius: '5px',
                  }
                }}
              />
            </Box>
            <Box ref={subtitleRef}>
              <TextAnimation 
                text="פתרונות אבטחה ומתח נמוך מתקדמים לבית ולעסק בהתאמה אישית ובמחירים הוגנים" 
                variant="h5" 
                delay={0.5}
                duration={0.7}
                sx={{ 
                  mb: 6, 
                  maxWidth: '800px',
                  fontSize: { xs: '1.1rem', md: '1.5rem' },
                  fontWeight: 300,
                  lineHeight: 1.6,
                  textShadow: '0 1px 3px rgba(0,0,0,0.2)',
                }}
              />
            </Box>
            <Box ref={ctaRef}>
              <MagneticButton 
                variant="contained" 
                size="large"
                endIcon={<PhoneIcon />}
                onClick={() => contactRef.current?.scrollIntoView({ behavior: 'smooth' })}
                strength={30}
                radius={100}
                sx={{ 
                  borderRadius: '30px', 
                  px: 5,
                  py: 2,
                  fontSize: '1.1rem',
                  backgroundColor: '#2196f3',
                  boxShadow: '0 10px 30px rgba(33, 150, 243, 0.4)',
                  '&:hover': {
                    backgroundColor: '#1976d2',
                    boxShadow: '0 15px 35px rgba(33, 150, 243, 0.5)',
                  },
                  transition: 'all 0.3s cubic-bezier(0.2, 0.8, 0.2, 1)'
                }}
              >
                צור קשר עכשיו
              </MagneticButton>
            </Box>
          </Box>
        </Container>
        
        {/* Scroll indicator */}
        <IconButton 
          sx={{ 
            position: 'absolute', 
            bottom: 40, 
            left: '50%', 
            transform: 'translateX(-50%)',
            color: 'white',
            animation: 'bounce 2s infinite',
            '@keyframes bounce': {
              '0%, 20%, 50%, 80%, 100%': { transform: 'translateY(0) translateX(-50%)' },
              '40%': { transform: 'translateY(-20px) translateX(-50%)' },
              '60%': { transform: 'translateY(-10px) translateX(-50%)' }
            }
          }}
          onClick={() => servicesRef.current?.scrollIntoView({ behavior: 'smooth' })}
        >
          <svg width="30" height="30" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7 10L12 15L17 10" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>
        </IconButton>
        
        {/* אלמנטים צפים */}
        <FloatingElements 
          count={15} 
          colors={['#2196f3', '#90caf9', '#1565c0']} 
          size={{ min: 10, max: 40 }}
          sx={{ opacity: 0.3 }}
        />
        
        {/* שכבות פאראלקס */}
        <ParallaxLayer
          depth={0.2}
          direction="both"
          sx={{
            position: 'absolute',
            top: '10%',
            left: '5%',
            width: '150px',
            height: '150px',
            borderRadius: '50%',
            background: 'radial-gradient(circle, rgba(33,150,243,0.3) 0%, rgba(33,150,243,0) 70%)',
            filter: 'blur(20px)',
            zIndex: 0
          }}
        />
        <ParallaxLayer
          depth={0.1}
          direction="both"
          sx={{
            position: 'absolute',
            bottom: '20%',
            right: '10%',
            width: '200px',
            height: '200px',
            borderRadius: '50%',
            background: 'radial-gradient(circle, rgba(21,101,192,0.2) 0%, rgba(21,101,192,0) 70%)',
            filter: 'blur(15px)',
            zIndex: 0
          }}
        />
      </Box>
      
      {/* Scroll Progress */}
      <ScrollProgress />

      {/* Services Content */}
      <Box 
        ref={servicesRef}
        className={`services-section ${activeSection === 'services' ? 'active-section' : ''}`}
        sx={{ 
          py: { xs: 10, md: 15 },
          backgroundColor: '#f7f9fc',
          position: 'relative',
          zIndex: 1,
          '&:before': {
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundImage: 'radial-gradient(circle at 20% 90%, rgba(33, 150, 243, 0.05) 0%, transparent 50%), radial-gradient(circle at 80% 30%, rgba(33, 150, 243, 0.05) 0%, transparent 50%)',
            zIndex: -1,
          }
        }}
      >
        <Container maxWidth="lg">
          <Box 
            sx={{ 
              mb: { xs: 8, md: 12 },
              textAlign: 'center',
              position: 'relative',
            }}
          >
            <Typography 
              variant="overline" 
              sx={{ 
                color: '#2196f3',
                fontWeight: 600,
                letterSpacing: 2,
                mb: 2,
                display: 'block',
                opacity: 0.9,
              }}
            >
              הפתרונות שלנו
            </Typography>
            <TextAnimation 
              text="שירותים מתקדמים לבית ולעסק" 
              variant="h2" 
              component="h2"
              delay={0.2}
              duration={0.8}
              sx={{ 
                fontWeight: 800,
                mb: 3,
                fontSize: { xs: '2rem', md: '3.5rem' },
                background: 'linear-gradient(90deg, #2196f3, #0d47a1)',
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
                textShadow: '0 2px 5px rgba(0,0,0,0.05)',
              }}
            />
            <TextAnimation 
              text="הצוות המקצועי שלנו מתמחה במגוון פתרונות אבטחה ומתח נמוך המותאמים לצרכים הייחודיים שלכם" 
              variant="h6" 
              delay={0.4}
              duration={0.6}
              sx={{ 
                maxWidth: '800px',
                mx: 'auto',
                color: 'text.secondary',
                fontWeight: 400,
                lineHeight: 1.6,
                mb: 6,
              }}
            />
          </Box>
          
          <Box sx={{ position: 'relative' }}>
            {services.map((service, index) => (
              <Box 
                key={index} 
                ref={(el) => serviceItemsRef.current[index] = el}
                sx={{ 
                  mb: 10,
                  opacity: 0,
                  transform: 'translateY(30px)',
                  animation: `fadeSlideUp 0.8s ${index * 0.2}s forwards cubic-bezier(0.2, 0.8, 0.2, 1)`,
                  '@keyframes fadeSlideUp': {
                    to: { opacity: 1, transform: 'translateY(0)' }
                  },
                }}
              >
                <Card 
                  elevation={0}
                  sx={{ 
                    overflow: 'visible',
                    position: 'relative',
                    borderRadius: 4,
                    transition: 'all 0.5s cubic-bezier(0.2, 0.8, 0.2, 1)',
                    border: '1px solid rgba(0,0,0,0.05)',
                    backgroundColor: 'white',
                    '&:hover': {
                      transform: 'translateY(-10px)',
                      boxShadow: '0 20px 40px rgba(0,0,0,0.08)',
                      '& .service-icon': {
                        transform: 'scale(1.1) rotate(10deg)',
                        boxShadow: '0 15px 35px rgba(33, 150, 243, 0.4)',
                      }
                    },
                  }}
                >
                  <Box 
                    className="service-icon"
                    sx={{ 
                      position: 'absolute',
                      top: { xs: -25, md: -30 },
                      right: { xs: 20, md: 40 },
                      width: { xs: 60, md: 80 },
                      height: { xs: 60, md: 80 },
                      borderRadius: '20px',
                      backgroundColor: '#2196f3',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      color: 'white',
                      boxShadow: '0 10px 30px rgba(33, 150, 243, 0.3)',
                      zIndex: 2,
                      transition: 'all 0.5s cubic-bezier(0.2, 0.8, 0.2, 1)',
                    }}
                  >
                    <service.icon sx={{ fontSize: { xs: '1.8rem', md: '2.5rem' } }} />
                  </Box>
                  <CardContent sx={{ pt: { xs: 5, md: 6 }, px: { xs: 3, md: 5 }, pb: { xs: 4, md: 5 } }}>
                    <Grid container spacing={{ xs: 3, md: 5 }}>
                      <Grid item xs={12} md={5}>
                        <Box sx={{ pt: { xs: 2, md: 3 } }}>
                          <Typography 
                            variant="h4" 
                            component="h3" 
                            gutterBottom 
                            sx={{ 
                              fontWeight: 700,
                              fontSize: { xs: '1.5rem', md: '2rem' },
                              color: '#1a1a1a',
                              mb: 2,
                            }}
                          >
                            {service.title}
                          </Typography>
                          <Typography 
                            variant="body1" 
                            paragraph 
                            sx={{ 
                              color: 'text.secondary', 
                              mb: 4,
                              lineHeight: 1.7,
                              fontSize: { xs: '0.95rem', md: '1rem' },
                            }}
                          >
                            {service.description}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={12} md={7}>
                        <Box 
                          sx={{ 
                            backgroundColor: 'rgba(33, 150, 243, 0.03)', 
                            borderRadius: 3,
                            p: { xs: 2, md: 4 },
                            border: '1px solid rgba(33, 150, 243, 0.1)',
                          }}
                        >
                          <Typography 
                            variant="h6" 
                            gutterBottom 
                            sx={{ 
                              fontWeight: 700, 
                              mb: 3,
                              color: '#1a1a1a',
                              fontSize: { xs: '1.1rem', md: '1.25rem' },
                            }}
                          >
                            מה כולל השירות:
                          </Typography>
                          <List sx={{ p: 0 }}>
                            {service.features.map((feature, idx) => (
                              <ListItem 
                                key={idx} 
                                sx={{ 
                                  py: 1.5,
                                  px: 0,
                                  borderBottom: idx !== service.features.length - 1 ? '1px solid rgba(0,0,0,0.05)' : 'none',
                                }}
                              >
                                <ListItemIcon sx={{ minWidth: { xs: 36, md: 42 } }}>
                                  <CheckCircleIcon sx={{ color: '#2196f3', fontSize: { xs: '1.2rem', md: '1.4rem' } }} />
                                </ListItemIcon>
                                <ListItemText 
                                  primary={feature} 
                                  primaryTypographyProps={{ 
                                    fontWeight: 500,
                                    fontSize: { xs: '0.9rem', md: '1rem' },
                                  }}
                                />
                              </ListItem>
                            ))}
                          </List>
                        </Box>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Box>
            ))}
          </Box>
        </Container>
      </Box>
      
      {/* Contact CTA Section */}
      <Box 
        ref={contactRef}
        className={`contact-section ${activeSection === 'contact' ? 'active-section' : ''}`}
        sx={{ 
          py: { xs: 12, md: 16 },
          backgroundColor: '#1976d2',
          color: 'white',
          position: 'relative',
          overflow: 'hidden',
          '&:before': {
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundImage: 'url("data:image/svg+xml,%3Csvg width=\'100\' height=\'100\' viewBox=\'0 0 100 100\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Cpath d=\'M11 18c3.866 0 7-3.134 7-7s-3.134-7-7-7-7 3.134-7 7 3.134 7 7 7zm48 25c3.866 0 7-3.134 7-7s-3.134-7-7-7-7 3.134-7 7 3.134 7 7 7zm-43-7c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zm63 31c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zM34 90c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zm56-76c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zM12 86c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm28-65c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm23-11c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm-6 60c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm29 22c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zM32 63c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm57-13c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm-9-21c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM60 91c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM35 41c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM12 60c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2z\' fill=\'%23ffffff\' fill-opacity=\'0.05\' fill-rule=\'evenodd\'/%3E%3C/svg%3E")',
            backgroundSize: '24px',
            zIndex: 0,
          }
        }}
      >
        <Container maxWidth="lg">
          <Box 
            sx={{ 
              textAlign: 'center',
              position: 'relative',
              zIndex: 1,
              opacity: 0,
              transform: 'translateY(30px)',
              animation: 'fadeIn 0.8s forwards 0.2s',
              '@keyframes fadeIn': {
                to: { opacity: 1, transform: 'translateY(0)' }
              }
            }}
          >
            <Typography 
              variant="overline" 
              sx={{ 
                color: 'rgba(255,255,255,0.8)',
                fontWeight: 600,
                letterSpacing: 2,
                mb: 2,
                display: 'block',
              }}
            >
              צור קשר
            </Typography>
            <Typography 
              variant="h2" 
              component="h2" 
              gutterBottom 
              sx={{ 
                fontWeight: 800,
                mb: 3,
                fontSize: { xs: '2.2rem', md: '3.5rem' },
                textShadow: '0 2px 10px rgba(0,0,0,0.1)',
              }}
            >
              מעוניינים בשירותים שלנו?
            </Typography>
            <Typography 
              variant="h6" 
              paragraph 
              sx={{ 
                mb: 6, 
                opacity: 0.9,
                maxWidth: '700px',
                mx: 'auto',
                fontWeight: 400,
                lineHeight: 1.6,
                fontSize: { xs: '1rem', md: '1.25rem' },
              }}
            >
              צוות המומחים שלנו ישמח להעניק לכם ייעוץ אישי והצעת מחיר ללא התחייבות
            </Typography>
            <Box sx={{ display: 'flex', justifyContent: 'center', gap: 3, flexWrap: 'wrap' }}>
              <Button 
                variant="contained" 
                size="large"
                endIcon={<PhoneIcon />}
                component={Link}
                to="/contact"
                sx={{ 
                  borderRadius: '30px', 
                  px: 5,
                  py: 2,
                  fontSize: '1.1rem',
                  backgroundColor: 'white',
                  color: '#1976d2',
                  boxShadow: '0 10px 30px rgba(0,0,0,0.15)',
                  '&:hover': {
                    backgroundColor: 'white',
                    transform: 'translateY(-5px) scale(1.05)',
                    boxShadow: '0 15px 35px rgba(0,0,0,0.2)',
                  },
                  transition: 'all 0.3s cubic-bezier(0.2, 0.8, 0.2, 1)'
                }}
              >
                צור קשר עכשיו
              </Button>
              <Button 
                variant="outlined" 
                size="large"
                component={Link}
                to="/about"
                sx={{ 
                  borderRadius: '30px', 
                  px: 5,
                  py: 2,
                  fontSize: '1.1rem',
                  borderColor: 'rgba(255,255,255,0.5)',
                  color: 'white',
                  '&:hover': {
                    borderColor: 'white',
                    backgroundColor: 'rgba(255,255,255,0.1)',
                    transform: 'translateY(-5px)',
                  },
                  transition: 'all 0.3s cubic-bezier(0.2, 0.8, 0.2, 1)'
                }}
              >
                קרא עוד עלינו
              </Button>
            </Box>
          </Box>
        </Container>
      </Box>
      
      {/* Floating navigation dots */}
      {!isMobile && (
        <Box 
          sx={{ 
            position: 'fixed',
            top: '50%',
            right: 20,
            transform: 'translateY(-50%)',
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            zIndex: 100,
          }}
        >
          {['hero', 'services', 'contact'].map((section) => (
            <Box 
              key={section}
              onClick={() => {
                if (section === 'hero') heroRef.current?.scrollIntoView({ behavior: 'smooth' });
                if (section === 'services') servicesRef.current?.scrollIntoView({ behavior: 'smooth' });
                if (section === 'contact') contactRef.current?.scrollIntoView({ behavior: 'smooth' });
              }}
              sx={{ 
                width: 12,
                height: 12,
                borderRadius: '50%',
                backgroundColor: activeSection === section ? '#2196f3' : 'rgba(0,0,0,0.2)',
                cursor: 'pointer',
                transition: 'all 0.3s ease',
                '&:hover': {
                  transform: 'scale(1.3)',
                  backgroundColor: activeSection === section ? '#2196f3' : 'rgba(0,0,0,0.4)',
                }
              }}
            />
          ))}
        </Box>
      )}
    </Box>
  );
}

export default Services;
