import React, { useEffect, useRef, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { 
  Box, 
  Container, 
  Typography, 
  Button, 
  Grid,
  Card,
  CardContent,
  CardActions,
  Avatar
} from '@mui/material';
import { motion } from 'framer-motion';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import SecurityIcon from '@mui/icons-material/Security';
import ElectricalServicesIcon from '@mui/icons-material/ElectricalServices';
import BuildIcon from '@mui/icons-material/Build';
import HandymanIcon from '@mui/icons-material/Handyman';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import { initializeAnimations } from '../utils/scrollAnimation';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

// ייבוא הקומפוננטות החדשות
import TextAnimation from '../components/TextAnimation';
import ParallaxLayer from '../components/ParallaxLayer';
import FloatingElements from '../components/FloatingElements';
import ScrollProgress from '../components/ScrollProgress';
import MagneticButton from '../components/MagneticButton';

// רישום פלאגין ScrollTrigger של GSAP
gsap.registerPlugin(ScrollTrigger);

function Home() {
  // הגדרת אלמנטים לאנימציית גלילה עם useRef
  const heroRef = useRef(null);
  const servicesRef = useRef(null);
  const featuresRef = useRef(null);
  const statsRef = useRef(null);
  
  // הגדרת refs לאנימציות GSAP
  const titleRef = useRef(null);
  const subtitleRef = useRef(null);
  const ctaRef = useRef(null);
  const heroGraphicsRef = useRef(null);
  const circleRef = useRef(null);
  const circle2Ref = useRef(null);
  const shapeRef = useRef(null);
  const shape2Ref = useRef(null);
  
  // הגדרת state לאנימציות אינטראקטיביות
  const [isLoaded, setIsLoaded] = useState(false);
  
  // שימוש במשתנים בקומפוננטה
  useEffect(() => {
    // הפעלת אנימציות לאחר טעינת הדף
    const timer = setTimeout(() => {
      setIsLoaded(true);
    }, 500);
    
    return () => clearTimeout(timer);
  }, []);
  
  // אתחול אנימציות GSAP
  useEffect(() => {
    // וידוא שכל ה-refs קיימים לפני הפעלת האנימציות
    // שימוש במשתנה isLoaded כדי להפעיל אנימציות רק לאחר טעינה
    if (!isLoaded) return;
    
    // בדיקה שכל ה-refs הנחוצים קיימים
    const requiredRefs = [titleRef, subtitleRef, ctaRef];
    const missingRefs = requiredRefs.filter(ref => !ref.current);
    
    if (missingRefs.length > 0) {
      console.warn('Some refs are not available yet:', missingRefs);
      return;
    }

    try {
      // אנימציית הכותרת והטקסט
      const tl = gsap.timeline({ defaults: { ease: "power3.out" } });
      
      // אנימציית כותרת
      tl.from(titleRef.current, { 
        y: 50, 
        opacity: 0, 
        duration: 1,
        delay: 0.2
      });
      
      // אנימציית תת-כותרת
      if (subtitleRef.current) {
        tl.from(subtitleRef.current, { 
          y: 30, 
          opacity: 0, 
          duration: 0.8 
        }, "-=0.6");
      }
      
      // אנימציית כפתורים - משופרת
      if (ctaRef.current && ctaRef.current.children && ctaRef.current.children.length > 0) {
        tl.from(ctaRef.current.children, { 
          y: 20, 
          opacity: 0, 
          duration: 0.6,
          stagger: 0.2,
          ease: "back.out(1.7)"
        }, "-=0.4");
      }
      
      // אנימציית אלמנטים גרפיים
      const circleElements = [];
      if (circleRef.current) circleElements.push(circleRef.current);
      if (circle2Ref.current) circleElements.push(circle2Ref.current);
      
      if (circleElements.length > 0) {
        tl.from(circleElements, {
          scale: 0,
          opacity: 0,
          duration: 1.5,
          stagger: 0.3,
          ease: "elastic.out(1, 0.3)"
        }, "-=1");
      }
      
      const shapeElements = [];
      if (shapeRef.current) shapeElements.push(shapeRef.current);
      if (shape2Ref.current) shapeElements.push(shape2Ref.current);
      
      if (shapeElements.length > 0) {
        tl.from(shapeElements, {
          rotate: -90,
          opacity: 0,
          duration: 1,
          stagger: 0.2,
          ease: "back.out(1.7)"
        }, "-=1.5");
      }
      
      // אנימציות ScrollTrigger רק אם האלמנטים קיימים - משופרות
      if (servicesRef.current && document.querySelectorAll(".service-card").length > 0) {
        gsap.from(".service-card", {
          scrollTrigger: {
            trigger: servicesRef.current,
            start: "top 80%",
            toggleActions: "play none none none",
            markers: false
          },
          y: 50,
          scale: 0.95,
          opacity: 0,
          duration: 0.8,
          stagger: 0.2,
          ease: "power3.out"
        });
      }
      
      if (featuresRef.current && document.querySelectorAll(".feature-item").length > 0) {
        gsap.from(".feature-item", {
          scrollTrigger: {
            trigger: featuresRef.current,
            start: "top 70%",
            toggleActions: "play none none none"
          },
          x: -50,
          opacity: 0,
          duration: 0.6,
          stagger: 0.15
        });
      }
      
      if (statsRef.current && document.querySelectorAll(".stat-item").length > 0) {
        gsap.from(".stat-item", {
          scrollTrigger: {
            trigger: statsRef.current,
            start: "top 80%",
            toggleActions: "play none none none"
          },
          scale: 0.8,
          opacity: 0,
          duration: 0.8,
          stagger: 0.2,
          ease: "back.out(1.7)"
        });
      }
      
      // אנימציית הרקע הגרפי - רק אם האלמנטים קיימים
      if (circleRef.current && circle2Ref.current && shapeRef.current && shape2Ref.current) {
        const heroGraphicsTl = gsap.timeline({
          repeat: -1,
          yoyo: true,
          defaults: { ease: "sine.inOut" }
        });
        
        heroGraphicsTl
          .to(circleRef.current, { x: '10%', y: '5%', duration: 8 }, 0)
          .to(circle2Ref.current, { x: '-8%', y: '-7%', duration: 9 }, 0)
          .to(shapeRef.current, { rotate: 15, duration: 12 }, 0)
          .to(shape2Ref.current, { rotate: -20, duration: 10 }, 0);
      }
      
      // אתחול אנימציות גלילה רגילות
      initializeAnimations();
      
      // ניקוי בעת unmount
      return () => {
        if (tl) tl.kill();
        ScrollTrigger.getAll().forEach(trigger => trigger.kill());
      };
    } catch (error) {
      console.error('Error initializing GSAP animations:', error);
    }
  }, [isLoaded]); // הוספת isLoaded כתלות

  // אפקט הטעינה הראשוני
  useEffect(() => {
    // הגדרת טיימר לטעינה
    const timer = setTimeout(() => {
      setIsLoaded(true);
    }, 500);
    
    return () => clearTimeout(timer);
  }, []);

  return (
    <Box>
      {/* רכיב התקדמות הגלילה */}
      <ScrollProgress color="#2196f3" thickness={4} />
      
      {/* Hero Section */}
      <Box 
        ref={heroRef}
        className="hero-section scroll-section"
        sx={{ 
          background: 'linear-gradient(135deg, #0d47a1 0%, #1a237e 100%)',
          minHeight: '100vh',
          position: 'relative',
          overflow: 'hidden',
        }}
      >
        {/* אלמנטים צפים ברקע */}
        <FloatingElements 
          count={15} 
          colors={['#2196f3', '#90caf9', '#1565c0', '#bbdefb']} 
          size={{ min: 5, max: 40 }}
        />
          <ParallaxLayer depth={0.2} direction="both">
            <Box 
              ref={circleRef}
              sx={{
                position: 'absolute',
                top: '-10%',
                right: '-5%',
                width: '500px',
                height: '500px',
                borderRadius: '50%',
                background: 'radial-gradient(circle at center, rgba(33, 150, 243, 0.4) 0%, rgba(25, 118, 210, 0.1) 70%)',
                filter: 'blur(60px)',
              }}
            />
          </ParallaxLayer>
          
          <ParallaxLayer depth={0.3} direction="both">
            <Box 
              ref={circle2Ref}
              sx={{
                position: 'absolute',
                bottom: '-15%',
                left: '-10%',
                width: '600px',
                height: '600px',
                borderRadius: '50%',
                background: 'radial-gradient(circle at center, rgba(13, 71, 161, 0.6) 0%, rgba(25, 118, 210, 0.1) 70%)',
                filter: 'blur(80px)',
              }}
            />
          </ParallaxLayer>
          
          <ParallaxLayer depth={0.15} direction="both" rotation={15}>
            <Box 
              ref={shapeRef}
              sx={{
                position: 'absolute',
                top: '15%',
                left: '10%',
                width: '150px',
                height: '150px',
                borderRadius: '30% 70% 70% 30% / 30% 30% 70% 70%',
                border: '2px solid rgba(255, 255, 255, 0.1)',
                boxShadow: '0 0 30px rgba(33, 150, 243, 0.3)',
              }}
            />
          </ParallaxLayer>
          <ParallaxLayer depth={0.25} direction="both" rotation={-10}>
            <Box 
              ref={shape2Ref}
              sx={{
                position: 'absolute',
                bottom: '20%',
                right: '15%',
                width: '100px',
                height: '100px',
                borderRadius: '63% 37% 54% 46% / 55% 48% 52% 45%',
                border: '2px solid rgba(255, 255, 255, 0.1)',
                boxShadow: '0 0 20px rgba(25, 118, 210, 0.3)',
              }}
            />
          </ParallaxLayer>
          
          {/* אלמנטים נוספים לעיצוב מורכב יותר */}
          <Box 
            sx={{
              position: 'absolute',
              top: '40%',
              right: '20%',
              width: '200px',
              height: '200px',
              background: 'linear-gradient(135deg, rgba(33, 150, 243, 0.1) 0%, rgba(25, 118, 210, 0.1) 100%)',
              backdropFilter: 'blur(10px)',
              borderRadius: '20px',
              transform: 'rotate(30deg)',
              border: '1px solid rgba(255, 255, 255, 0.1)',
            }}
          />
          <Box 
            sx={{
              position: 'absolute',
              bottom: '30%',
              left: '25%',
              width: '120px',
              height: '120px',
              background: 'linear-gradient(135deg, rgba(13, 71, 161, 0.1) 0%, rgba(25, 118, 210, 0.1) 100%)',
              backdropFilter: 'blur(10px)',
              borderRadius: '50%',
              border: '1px solid rgba(255, 255, 255, 0.1)',
            }}
          />
        </Box>
        {/* אלמנטים גרפיים מונפשים עם GSAP */}
        <Box 
          ref={heroGraphicsRef}
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            overflow: 'hidden',
            zIndex: 2,
          }}
        >
        <Container maxWidth="lg" className="hero-content" sx={{ position: 'relative', zIndex: 3 }}>
          <Grid container spacing={4} alignItems="center" sx={{ position: 'relative' }}>
            <Grid item xs={12} md={7}>
              <Box ref={titleRef}>
                <TextAnimation 
                  text="פתרונות אבטחה"
                  variant="h2"
                  delay={0.2}
                  duration={0.7}
                  staggerDelay={0.03}
                  sx={{ 
                    fontSize: { xs: '2.5rem', md: '4rem' },
                    fontWeight: 'bold',
                    textShadow: '0 2px 10px rgba(0,0,0,0.2)',
                    position: 'relative',
                    zIndex: 4,
                    mb: 1,
                    background: 'linear-gradient(90deg, #ffffff, #bbdefb)',
                    WebkitBackgroundClip: 'text',
                    WebkitTextFillColor: 'transparent',
                  }}
                />
                <TextAnimation 
                  text="חכמים ומתקדמים"
                  variant="h2"
                  delay={0.8}
                  duration={0.7}
                  staggerDelay={0.03}
                  color="#90caf9"
                  sx={{ 
                    fontSize: { xs: '2.5rem', md: '4rem' },
                    fontWeight: 'bold',
                    textShadow: '0 2px 10px rgba(0,0,0,0.2)',
                    position: 'relative',
                    zIndex: 4,
                    mb: 2,
                  }}
                />
              </Box>
              <Box ref={subtitleRef}>
                <TextAnimation 
                  text="מערכות מצלמות אבטחה, אזעקות ומתח נמוך מהמובילות בשוק"
                  variant="h5"
                  delay={1.2}
                  duration={0.5}
                  staggerDelay={0.01}
                  sx={{ 
                    fontWeight: 400,
                    color: 'rgba(255, 255, 255, 0.9)',
                    lineHeight: 1.5,
                    mb: 4
                  }}
                />
              </Box>
              <Box 
                ref={ctaRef}
                sx={{ mt: 4, display: 'flex', flexWrap: 'wrap' }}
              >
                <MagneticButton 
                  variant="contained" 
                  size="large" 
                  component={RouterLink} 
                  to="/contact"
                  strength={30}
                  radius={120}
                  sx={{ 
                    mr: 2, 
                    mb: { xs: 2, sm: 0 },
                    px: 4,
                    py: 1.8,
                    fontWeight: 'bold',
                    fontSize: '1.1rem',
                    boxShadow: '0 10px 20px rgba(0,0,0,0.1)',
                    '&:hover': {
                      transform: 'translateY(-5px)',
                      boxShadow: '0 15px 30px rgba(0,0,0,0.2)',
                    },
                    transition: 'all 0.3s cubic-bezier(0.2, 0.8, 0.2, 1)'
                  }}
                >
                  צור קשר
                </MagneticButton>
                <MagneticButton 
                  variant="outlined" 
                  size="large" 
                  component={RouterLink} 
                  to="/services"
                  strength={20}
                  radius={100}
                  sx={{ 
                    backgroundColor: 'rgba(255, 255, 255, 0.15)',
                    backdropFilter: 'blur(10px)',
                    color: 'white',
                    borderColor: 'rgba(255, 255, 255, 0.5)',
                    '&:hover': {
                      backgroundColor: 'rgba(255, 255, 255, 0.25)',
                      borderColor: 'white',
                      transform: 'translateY(-5px)',
                    },
                    px: 4,
                    py: 1.8,
                    fontWeight: 'bold',
                    fontSize: '1.1rem',
                    transition: 'all 0.3s cubic-bezier(0.2, 0.8, 0.2, 1)'
                  }}
                >
                  השירותים שלנו
                </MagneticButton>
              </Box>
            </Grid>
            <Grid item xs={12} md={5}>
              <Box 
                className="scroll-reveal"
                sx={{ 
                  display: { xs: 'none', md: 'block' },
                  backgroundColor: 'rgba(255, 255, 255, 0.1)',
                  p: 4,
                  backdropFilter: 'blur(10px)',
                  border: '1px solid rgba(255, 255, 255, 0.2)',
                  borderRadius: '12px',
                  position: 'relative',
                  overflow: 'hidden',
                  boxShadow: '0 20px 40px rgba(0,0,0,0.2)',
                  zIndex: 4,
                }}
              >
                {/* לוגו שיא מיגון */}
                <Box sx={{ textAlign: 'center', mb: 2 }}>
                  <img 
                    src="/logo192.png" 
                    alt="שיא מיגון" 
                    style={{ 
                      width: '120px', 
                      height: 'auto',
                      filter: 'drop-shadow(0 10px 20px rgba(0,0,0,0.2))',
                    }} 
                  />
                </Box>
                <Typography variant="h5" fontWeight="bold" mb={2} textAlign="center">
                  שיא מיגון
                </Typography>
                <Typography variant="body1" sx={{ opacity: 0.9, lineHeight: 1.7 }}>
                  אנו מתמחים בהתקנת מערכות אבטחה חכמות ומתקדמות המשלבות טכנולוגיה חדשנית עם שירות מקצועי ואמין.
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>

      {/* Services Section */}
      <Box 
        ref={servicesRef}
        className="services-section scroll-section"
        sx={{ 
          py: 10,
          backgroundColor: 'background.paper',
          position: 'relative',
          overflow: 'hidden',
        }}
      >
        {/* רקע דקורטיבי */}
        <Box 
          sx={{
            position: 'absolute',
            top: '-5%',
            right: '-10%',
            width: '500px',
            height: '500px',
            borderRadius: '50%',
            background: 'radial-gradient(circle, rgba(227, 30, 36, 0.05) 0%, rgba(227, 30, 36, 0) 70%)',
            zIndex: 0,
          }}
        />
        
        <Container maxWidth="lg" sx={{ position: 'relative', zIndex: 1 }}>
          <Typography 
            variant="h3" 
            component="h2" 
            className="section-title scroll-reveal"
            gutterBottom
            mb={8}
            sx={{
              fontWeight: 900,
              textAlign: 'center',
            }}
          >
            השירותים שלנו
          </Typography>
          
          <Grid container spacing={4}>
            <Grid item xs={12} md={4} className="service-card">
              <Card 
                className="service-card scroll-reveal scroll-reveal-delay-1"
                elevation={0}
                sx={{ 
                  p: 3,
                  height: '100%',
                  transition: 'all 0.3s ease',
                  '&:hover': {
                    transform: 'translateY(-10px)',
                    boxShadow: '0 10px 20px rgba(0,0,0,0.1)',
                  },
                }}
              >
                <CardContent>
                  <Box sx={{ textAlign: 'center', mb: 3 }}>
                    <CameraAltIcon className="service-icon" sx={{ fontSize: '4rem' }} />
                  </Box>
                  <Typography variant="h5" component="h3" gutterBottom align="center" fontWeight="bold">
                    מצלמות אבטחה
                  </Typography>
                  <Typography variant="body1" color="text.secondary" sx={{ mb: 3 }}>
                    מערכות מצלמות אבטחה מתקדמות עם יכולות צפייה מרחוק, הקלטה באיכות HD, זיהוי תנועה חכם ואחסון בענן.
                  </Typography>
                </CardContent>
                <CardActions sx={{ justifyContent: 'center' }}>
                  <Button 
                    component={RouterLink} 
                    to="/services"
                    variant="outlined"
                    endIcon={<motion.div whileHover={{ x: 5 }} transition={{ duration: 0.2 }}>→</motion.div>}
                  >
                    למידע נוסף
                  </Button>
                </CardActions>
              </Card>
            </Grid>
            
            <Grid item xs={12} md={4} className="service-card">
              <Card 
                className="service-card scroll-reveal scroll-reveal-delay-2"
                elevation={0}
                sx={{ 
                  p: 3,
                  height: '100%',
                  transition: 'all 0.3s ease',
                  '&:hover': {
                    transform: 'translateY(-10px)',
                    boxShadow: '0 10px 20px rgba(0,0,0,0.1)',
                  },
                }}
              >
                <CardContent>
                  <Box sx={{ textAlign: 'center', mb: 3 }}>
                    <SecurityIcon className="service-icon" sx={{ fontSize: '4rem' }} />
                  </Box>
                  <Typography variant="h5" component="h3" gutterBottom align="center" fontWeight="bold">
                    מערכות אזעקה
                  </Typography>
                  <Typography variant="body1" color="text.secondary" sx={{ mb: 3 }}>
                    מערכות אזעקה מתקדמות עם חיישני תנועה, חיישני דלתות וחלונות, התראות בזמן אמת לטלפון הנייד וחיבור למוקד.
                  </Typography>
                </CardContent>
                <CardActions sx={{ justifyContent: 'center' }}>
                  <Button 
                    component={RouterLink} 
                    to="/services"
                    variant="outlined"
                    endIcon={<motion.div whileHover={{ x: 5 }} transition={{ duration: 0.2 }}>→</motion.div>}
                  >
                    למידע נוסף
                  </Button>
                </CardActions>
              </Card>
            </Grid>
            
            <Grid item xs={12} md={4} className="service-card">
              <Card 
                className="service-card scroll-reveal scroll-reveal-delay-3"
                elevation={0}
                sx={{ 
                  p: 3,
                  height: '100%',
                  transition: 'all 0.3s ease',
                  '&:hover': {
                    transform: 'translateY(-10px)',
                    boxShadow: '0 10px 20px rgba(0,0,0,0.1)',
                  },
                }}
              >
                <CardContent>
                  <Box sx={{ textAlign: 'center', mb: 3 }}>
                    <ElectricalServicesIcon className="service-icon" sx={{ fontSize: '4rem' }} />
                  </Box>
                  <Typography variant="h5" component="h3" gutterBottom align="center" fontWeight="bold">
                    מערכות מתח נמוך
                  </Typography>
                  <Typography variant="body1" color="text.secondary" sx={{ mb: 3 }}>
                    התקנת מערכות מתח נמוך כולל אינטרקום, בקרת כניסה, מערכות אודיו-וידאו, רשתות תקשורת ועוד.
                  </Typography>
                </CardContent>
                <CardActions sx={{ justifyContent: 'center' }}>
                  <Button 
                    component={RouterLink} 
                    to="/services"
                    variant="outlined"
                    endIcon={<motion.div whileHover={{ x: 5 }} transition={{ duration: 0.2 }}>→</motion.div>}
                  >
                    למידע נוסף
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </Box>

      {/* Why Choose Us Section */}
      <Box 
        ref={featuresRef}
        className="section-padding scroll-section"
        sx={{ 
          backgroundColor: '#f8f8f8',
          position: 'relative',
          overflow: 'hidden',
        }}
      >
        <Container maxWidth="lg">
          <Typography 
            variant="h3" 
            component="h2" 
            className="section-title scroll-reveal"
            gutterBottom
            mb={8}
            sx={{
              fontWeight: 900,
              textAlign: 'center',
            }}
          >
            למה לבחור בנו
          </Typography>
          
          <Grid container spacing={5} alignItems="center">
            <Grid item xs={12} md={6} className="feature-item">
              <Box className="scroll-reveal fade-in-left">
                <img 
                  src="https://source.unsplash.com/random/600x400/?security,installation" 
                  alt="התקנת מערכות אבטחה"
                  style={{ 
                    width: '100%', 
                    height: 'auto',
                    boxShadow: '0 10px 30px rgba(0,0,0,0.15)',
                  }}
                />
              </Box>
            </Grid>
            
            <Grid item xs={12} md={6} className="feature-item">
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Box 
                    className="scroll-reveal scroll-reveal-delay-1"
                    sx={{ 
                      display: 'flex', 
                      alignItems: 'flex-start',
                      mb: 3,
                    }}
                  >
                    <Avatar 
                      sx={{ 
                        bgcolor: 'primary.main', 
                        mr: 2,
                        width: 56,
                        height: 56,
                      }}
                    >
                      <BuildIcon fontSize="large" />
                    </Avatar>
                    <Box>
                      <Typography variant="h6" fontWeight="bold" gutterBottom>
                        נסיון מקצועי רב שנים
                      </Typography>
                      <Typography variant="body1" color="text.secondary">
                        צוות הטכנאים שלנו מנוסה ובעל נסיון רב בהתקנת מערכות אבטחה מתקדמות בכל רחבי הארץ.
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
                
                <Grid item xs={12}>
                  <Box 
                    className="scroll-reveal scroll-reveal-delay-2"
                    sx={{ 
                      display: 'flex', 
                      alignItems: 'flex-start',
                      mb: 3,
                    }}
                  >
                    <Avatar 
                      sx={{ 
                        bgcolor: 'primary.main', 
                        mr: 2,
                        width: 56,
                        height: 56,
                      }}
                    >
                      <HandymanIcon fontSize="large" />
                    </Avatar>
                    <Box>
                      <Typography variant="h6" fontWeight="bold" gutterBottom>
                        ציוד איכותי ומתקדם
                      </Typography>
                      <Typography variant="body1" color="text.secondary">
                        אנו משתמשים רק בציוד האיכותי ביותר מהיצרנים המובילים בעולם, עם אחריות מלאה על כל התקנה.
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
                
                <Grid item xs={12}>
                  <Box 
                    className="scroll-reveal scroll-reveal-delay-3"
                    sx={{ 
                      display: 'flex', 
                      alignItems: 'flex-start'
                    }}
                  >
                    <Avatar 
                      sx={{ 
                        bgcolor: 'primary.main', 
                        mr: 2,
                        width: 56,
                        height: 56,
                      }}
                    >
                      <SupportAgentIcon fontSize="large" />
                    </Avatar>
                    <Box>
                      <Typography variant="h6" fontWeight="bold" gutterBottom>
                        שירות לקוחות מצוין
                      </Typography>
                      <Typography variant="body1" color="text.secondary">
                        אנו מחויבים לשירות לקוחות מצוין ומענה מהיר לכל פנייה, עם תמיכה טכנית זמינה 24/7.
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Box>

      {/* Stats Section */}
      <Box 
        ref={statsRef}
        className="section-padding scroll-section"
        sx={{ 
          backgroundColor: 'primary.main',
          color: 'white',
          py: 6,
        }}
      >
        <Container maxWidth="lg">
          <Grid container spacing={3} justifyContent="center">
            <Grid item xs={6} md={3}>
              <Box 
                className="scroll-reveal"
                sx={{ 
                  textAlign: 'center',
                  p: 2,
                }}
              >
                <Typography variant="h3" fontWeight="bold" gutterBottom>
                  1000+
                </Typography>
                <Typography variant="subtitle1">
                  לקוחות מרוצים
                </Typography>
              </Box>
            </Grid>
            
            <Grid item xs={6} md={3}>
              <Box 
                className="scroll-reveal scroll-reveal-delay-1"
                sx={{ 
                  textAlign: 'center',
                  p: 2,
                }}
              >
                <Typography variant="h3" fontWeight="bold" gutterBottom>
                  15+
                </Typography>
                <Typography variant="subtitle1">
                  שנות נסיון
                </Typography>
              </Box>
            </Grid>
            
            <Grid item xs={6} md={3}>
              <Box 
                className="scroll-reveal scroll-reveal-delay-2"
                sx={{ 
                  textAlign: 'center',
                  p: 2,
                }}
              >
                <Typography variant="h3" fontWeight="bold" gutterBottom>
                  5000+
                </Typography>
                <Typography variant="subtitle1">
                  התקנות מוצלחות
                </Typography>
              </Box>
            </Grid>
            
            <Grid item xs={6} md={3}>
              <Box 
                className="scroll-reveal scroll-reveal-delay-3"
                sx={{ 
                  textAlign: 'center',
                  p: 2,
                }}
              >
                <Typography variant="h3" fontWeight="bold" gutterBottom>
                  24/7
                </Typography>
                <Typography variant="subtitle1">
                  תמיכה טכנית
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Box>
  );
}

export default Home;
