import React, { useEffect, useRef } from 'react';
import { Box } from '@mui/material';
import gsap from 'gsap';

// קומפוננטה ליצירת שכבות פאראלקס מתקדמות
const ParallaxLayer = ({ 
  children, 
  depth = 0.1, 
  direction = 'vertical',
  scale = 1,
  rotation = 0,
  opacity = 1,
  ...props 
}) => {
  const layerRef = useRef(null);
  
  useEffect(() => {
    if (!layerRef.current) return;
    
    const handleMouseMove = (e) => {
      const { clientX, clientY } = e;
      const windowWidth = window.innerWidth;
      const windowHeight = window.innerHeight;
      
      // חישוב מיקום העכבר יחסית למרכז המסך
      const xPos = (clientX / windowWidth - 0.5);
      const yPos = (clientY / windowHeight - 0.5);
      
      // אנימציה חלקה לשכבה בהתאם לתנועת העכבר
      gsap.to(layerRef.current, {
        x: direction === 'horizontal' || direction === 'both' ? xPos * 100 * depth : 0,
        y: direction === 'vertical' || direction === 'both' ? yPos * 100 * depth : 0,
        rotation: rotation ? xPos * rotation : 0,
        scale: scale + (xPos * yPos * 0.05),
        duration: 1,
        ease: 'power2.out'
      });
    };
    
    window.addEventListener('mousemove', handleMouseMove);
    
    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, [depth, direction, scale, rotation]);
  
  return (
    <Box
      ref={layerRef}
      sx={{
        position: 'absolute',
        opacity: opacity,
        willChange: 'transform',
        ...props.sx
      }}
      {...props}
    >
      {children}
    </Box>
  );
};

export default ParallaxLayer;
