import React, { useEffect, useRef } from 'react';
import { Box } from '@mui/material';
import gsap from 'gsap';

// קומפוננטה ליצירת אלמנטים צפים עם אנימציות מורכבות
const FloatingElements = ({ count = 10, colors = ['#2196f3', '#90caf9', '#1565c0'], size = { min: 10, max: 50 }, ...props }) => {
  const containerRef = useRef(null);
  const elementsRef = useRef([]);
  
  useEffect(() => {
    if (!containerRef.current) return;
    
    // מערך לשמירת האלמנטים לניקוי
    const elements = [];
    
    // יצירת אנימציות לכל האלמנטים
    elementsRef.current.forEach((element, index) => {
      if (!element) return;
      
      // שמירת האלמנט במערך
      elements.push(element);
      
      // יצירת אנימציה ייחודית לכל אלמנט
      const timeline = gsap.timeline({
        repeat: -1,
        yoyo: true,
        defaults: { ease: 'sine.inOut' }
      });
      
      // זמן אקראי לכל אנימציה
      const duration = gsap.utils.random(15, 30);
      const delay = index * 0.2;
      
      // תנועה אקראית
      timeline.to(element, {
        x: gsap.utils.random(-100, 100),
        y: gsap.utils.random(-100, 100),
        rotation: gsap.utils.random(-180, 180),
        duration: duration,
        delay: delay
      });
      
      // שינוי גודל אקראי
      gsap.to(element, {
        scale: gsap.utils.random(0.8, 1.2),
        opacity: gsap.utils.random(0.5, 1),
        duration: gsap.utils.random(5, 10),
        repeat: -1,
        yoyo: true,
        ease: 'sine.inOut'
      });
    });
    
    // שמירת האלמנטים בקבוע לניקוי
    const elementsToCleanup = [...elements];
    
    return () => {
      // ניקוי האנימציות עבור כל אלמנט בנפרד
      elementsToCleanup.forEach(element => {
        if (element) gsap.killTweensOf(element);
      });
    };
  }, [count]);
  
  // יצירת מערך של אלמנטים צפים
  const renderElements = () => {
    const elements = [];
    
    for (let i = 0; i < count; i++) {
      // ערכים אקראיים לכל אלמנט
      const elementSize = gsap.utils.random(size.min, size.max);
      const color = colors[Math.floor(Math.random() * colors.length)];
      const posX = gsap.utils.random(0, 100);
      const posY = gsap.utils.random(0, 100);
      const opacity = gsap.utils.random(0.1, 0.5);
      const borderRadius = Math.random() > 0.5 ? '50%' : gsap.utils.random(10, 50) + '%';
      const blur = Math.random() > 0.7 ? gsap.utils.random(5, 20) : 0;
      
      elements.push(
        <Box
          key={i}
          ref={el => elementsRef.current[i] = el}
          sx={{
            position: 'absolute',
            width: elementSize,
            height: elementSize,
            backgroundColor: color,
            borderRadius: borderRadius,
            opacity: opacity,
            filter: blur ? `blur(${blur}px)` : 'none',
            top: `${posY}%`,
            left: `${posX}%`,
            transform: 'translate(-50%, -50%)',
            boxShadow: '0 0 20px rgba(33, 150, 243, 0.3)',
            willChange: 'transform, opacity'
          }}
        />
      );
    }
    
    return elements;
  };
  
  return (
    <Box
      ref={containerRef}
      sx={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        overflow: 'hidden',
        pointerEvents: 'none',
        zIndex: 1,
        ...props.sx
      }}
    >
      {renderElements()}
    </Box>
  );
};

export default FloatingElements;
