import React, { useEffect, useRef } from 'react';
import { Box, Typography } from '@mui/material';
import gsap from 'gsap';
import SplitType from 'split-type';

// קומפוננטה לאנימציית טקסט מתקדמת
const TextAnimation = ({ text, variant = 'h3', color = 'inherit', delay = 0, duration = 0.5, staggerDelay = 0.03, ...props }) => {
  const textRef = useRef(null);
  
  useEffect(() => {
    if (!textRef.current) return;
    
    // יצירת אנימציה פשוטה יותר עבור טקסט בעברית
    // במקום לפצל לאותיות, נשתמש באנימציה על הטקסט כולו
    try {
      // ננסה להשתמש ב-SplitType אם אפשר
      const splitText = new SplitType(textRef.current, { types: 'chars, words' });
      
      if (splitText && splitText.chars && splitText.chars.length > 0) {
        // אנימציה לכל אות בנפרד
        gsap.fromTo(
          splitText.chars,
          { 
            opacity: 0,
            y: 20,
            scale: 0.8,
          },
          { 
            opacity: 1,
            y: 0,
            scale: 1,
            duration: duration,
            stagger: staggerDelay,
            delay: delay,
            ease: 'back.out(1.7)'
          }
        );
        
        // ניקוי בעת unmount
        return () => {
          if (splitText) splitText.revert();
        };
      } else {
        // גיבוי אם SplitType לא עובד כראוי
        gsap.fromTo(
          textRef.current,
          { opacity: 0, y: 20 },
          { opacity: 1, y: 0, duration: duration, delay: delay, ease: 'back.out(1.7)' }
        );
      }
    } catch (error) {
      console.error('Error in TextAnimation:', error);
      // גיבוי במקרה של שגיאה
      gsap.fromTo(
        textRef.current,
        { opacity: 0, y: 20 },
        { opacity: 1, y: 0, duration: duration, delay: delay, ease: 'back.out(1.7)' }
      );
    }
  }, [delay, duration, staggerDelay, text]);
  
  return (
    <Box sx={{ overflow: 'hidden', width: '100%' }}>
      <Typography
        ref={textRef}
        variant={variant}
        color={color}
        {...props}
        sx={{
          display: 'inline-block',
          ...props.sx
        }}
      >
        {text}
      </Typography>
    </Box>
  );
};

export default TextAnimation;
