// קובץ המטפל באנימציות גלילה
export const handleScrollAnimations = () => {
  // בחירת כל האלמנטים עם מחלקות האנימציה
  const scrollElements = document.querySelectorAll(
    '.scroll-reveal, .fade-in-left, .fade-in-right, .scroll-reveal-delay-1, .scroll-reveal-delay-2, .scroll-reveal-delay-3, .section-title'
  );
  
  // בדיקה אם האלמנט נראה בחלון
  const elementInView = (el, scrollOffset = 100) => {
    const elementTop = el.getBoundingClientRect().top;
    return (
      elementTop <= 
      ((window.innerHeight || document.documentElement.clientHeight) - scrollOffset)
    );
  };
  
  // הצגת אלמנט עם אנימציה
  const displayScrollElement = (element) => {
    element.classList.add('active');
  };
  
  // הסתרת אלמנט
  const hideScrollElement = (element) => {
    // אם האלמנט יצא מהמסך למעלה, נסתיר אותו רק אם הוא לא בסקשן הראשון
    if (!element.closest('.hero-section')) {
      element.classList.remove('active');
    }
  };
  
  // טיפול באנימציות גלילה
  const handleScrollAnimation = () => {
    scrollElements.forEach((el) => {
      if (elementInView(el, 100)) {
        displayScrollElement(el);
      } else {
        hideScrollElement(el);
      }
    });
  };
  
  // אירוע גלילה
  window.addEventListener('scroll', () => {
    handleScrollAnimation();
  });
  
  // הפעלה ראשונית
  handleScrollAnimation();
};

// פונקציה לטיפול בלוגו מונפש
export const setupLogoAnimation = () => {
  const logoElements = document.querySelectorAll('.logo-animation');
  
  logoElements.forEach(logo => {
    logo.addEventListener('mouseenter', () => {
      logo.classList.add('animated');
    });
    
    logo.addEventListener('mouseleave', () => {
      setTimeout(() => {
        logo.classList.remove('animated');
      }, 500);
    });
  });
};

// פונקציה לטיפול בגלילה אינסופית
export const setupInfiniteScroll = () => {
  const sections = document.querySelectorAll('.scroll-section');
  
  // מעבר בין סקשנים בגלילה
  const handleSectionScroll = () => {
    sections.forEach(section => {
      const sectionTop = section.getBoundingClientRect().top;
      const sectionHeight = section.offsetHeight;
      const windowHeight = window.innerHeight;
      
      // חישוב אחוז הגלילה בתוך הסקשן
      const scrollPercentage = Math.min(
        Math.max(
          (windowHeight - sectionTop) / (sectionHeight + windowHeight) * 100,
          0
        ),
        100
      );
      
      // הפעלת אנימציות לפי אחוז הגלילה
      const parallaxElements = section.querySelectorAll('[data-parallax]');
      parallaxElements.forEach(element => {
        const speed = element.getAttribute('data-parallax') || 0.1;
        const movement = (scrollPercentage * speed);
        element.style.transform = `translateY(${movement}px)`;
      });
    });
  };
  
  window.addEventListener('scroll', handleSectionScroll);
  handleSectionScroll();
};

// פונקציה לגלילה חלקה - תחליף ל-CSS scroll-behavior
export const smoothScroll = (targetId, duration = 1000) => {
  const target = document.getElementById(targetId);
  if (!target) return;
  
  const targetPosition = target.getBoundingClientRect().top + window.pageYOffset;
  const startPosition = window.pageYOffset;
  const distance = targetPosition - startPosition;
  let startTime = null;
  
  const animation = (currentTime) => {
    if (startTime === null) startTime = currentTime;
    const timeElapsed = currentTime - startTime;
    const run = ease(timeElapsed, startPosition, distance, duration);
    window.scrollTo(0, run);
    if (timeElapsed < duration) requestAnimationFrame(animation);
  };
  
  // פונקציית האיזינג לגלילה חלקה
  const ease = (t, b, c, d) => {
    t /= d / 2;
    if (t < 1) return c / 2 * t * t + b;
    t--;
    return -c / 2 * (t * (t - 2) - 1) + b;
  };
  
  requestAnimationFrame(animation);
};

// פונקציה להוספת מאזינים לכל הקישורים הפנימיים
export const setupSmoothScrolling = () => {
  document.querySelectorAll('a[href^="#"]').forEach(anchor => {
    anchor.addEventListener('click', function(e) {
      e.preventDefault();
      const targetId = this.getAttribute('href').substring(1);
      if (targetId) {
        smoothScroll(targetId);
      }
    });
  });
};

// פונקציה ראשית להפעלת כל האנימציות
export const initializeAnimations = () => {
  handleScrollAnimations();
  setupLogoAnimation();
  setupInfiniteScroll();
  setupSmoothScrolling(); // הוספת גלילה חלקה
};
