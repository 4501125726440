import React, { useEffect, useRef } from 'react';
import { Box } from '@mui/material';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

// קומפוננטה ליצירת אינדיקטור התקדמות גלילה מתקדם
const ScrollProgress = ({ color = '#2196f3', thickness = 6, shadow = true, ...props }) => {
  const progressRef = useRef(null);
  
  useEffect(() => {
    if (!progressRef.current) return;
    
    gsap.registerPlugin(ScrollTrigger);
    
    // יצירת אנימציה להתקדמות הגלילה
    gsap.to(progressRef.current, {
      width: '100%',
      ease: 'none',
      scrollTrigger: {
        trigger: document.body,
        start: 'top top',
        end: 'bottom bottom',
        scrub: 0.3
      }
    });
    
    return () => {
      ScrollTrigger.getAll().forEach(trigger => trigger.kill());
    };
  }, []);
  
  return (
    <Box
      sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        height: thickness,
        zIndex: 9999,
        ...props.sx
      }}
    >
      <Box
        ref={progressRef}
        sx={{
          height: '100%',
          width: '0%',
          backgroundColor: color,
          boxShadow: shadow ? `0 0 10px ${color}` : 'none',
          borderRadius: '0 4px 4px 0',
          transition: 'box-shadow 0.3s ease'
        }}
      />
    </Box>
  );
};

export default ScrollProgress;
